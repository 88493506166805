<template>
	<div class="page">
		<el-breadcrumb separator=">>">
			<el-breadcrumb-item :to="{ path: '/' }" replace>
				{{lang==6?'首页':'Home'}}
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				{{lang==6?'关于我们':'About Us'}}
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				cooperation
			</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="title">
			{{info.title}}
		</div>
		<div class="text" v-html="info.describe"></div>
		<img :src="info.image" />
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				info: {}
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 12
				}).then(res => {
					this.info = res.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 111px;
		position: relative;
	}

	.el-breadcrumb {
		height: 40px;
		line-height: 40px;
		border-bottom: #999999 1px solid;
		position: absolute;
		top: 0;
		width: 100%;
		padding: 0 111px !important;
		color: #333333 !important;
	}

	/deep/.el-breadcrumb__inner {
		color: #333333 !important;
	}

	/deep/.is-link {
		font-weight: normal;
		color: #333333 !important;
	}

	/deep/ .el-breadcrumb__separator {
		color: #333333 !important;
	}

	.text {
		padding: 50px 111px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		line-height: 36px;
		color: #333333;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		color: #97233F;
		padding: 0 111px;
	}

	img {
		width: 100%;
		vertical-align: middle;
	}
</style>